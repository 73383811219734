import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Profile = () => (
    <section className="profile">
        <div className="profile__first">
            <StaticImage src="../images/profile.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="profile__image" />
            <h2 className="profile__title">みなみ＠HSP翻訳家</h2>
            <p className="profile__text">HSP（とても繊細な人）の英日フリーランス翻訳家。TOEIC
                965点。様々な職を転々とするも、自分らしく働くことを夢見て未経験から翻訳家になりました。今は自分の部屋で、マイペースに仕事をしながら暮らしています。自分の経験が皆さまの役に立つことを願ってこちらのブログを作りました。HSPやストレスフリーで生きたい人にとって、翻訳家は間違いなく天職になります。ご質問やお問い合わせは<Link to={"/contact/"} className="profile__link">コチラ</Link>まで！ なお、Amazonのアソシエイトとして、当メディアは適格販売により収入を得ています。
            </p>
            <h2 className="categories__title">カテゴリー</h2>
            <ul className="categories__list">
                <Link to={"/category/translation/"} className="categories__link">
                    <li className="categories__item">翻訳</li>
                </Link>
                <Link to={"/category/toeic/"} className="categories__link">
                    <li className="categories__item">TOEIC</li>
                </Link>
                <Link to={"/category/english/"} className="categories__link">
                    <li className="categories__item">英語</li>
                </Link>
                {/* <Link to={"/category/freelance/"} className="categories__link">
                    <li className="categories__item">フリーランス</li>
                </Link> */}
                <Link to={"/category/hsp/"} className="categories__link">
                    <li className="categories__item">HSP</li>
                </Link>
                <Link to={"/category/books/"} className="categories__link">
                    <li className="categories__item">参考書</li>
                </Link>
                <Link to={"/category/tools/"} className="categories__link">
                    <li className="categories__item">道具</li>
                </Link>
                <Link to={"/category/job/"} className="categories__link">
                    <li className="categories__item">仕事</li>
                </Link>
            </ul>
        </div>
        <div className="profile__second">
            <p className="ad4"><a href="https://px.a8.net/svt/ejp?a8mat=3N879D+354DPU+8FM+66WOX" rel="nofollow">
                <img alt="" src="https://www27.a8.net/svt/bgt?aid=220412353190&wid=001&eno=01&mid=s00000001093001040000&mc=1" width={160} height={600} border={0} /></a>
                <img src="https://www11.a8.net/0.gif?a8mat=3N879D+354DPU+8FM+66WOX" alt="" width={1} height={1} border={0} /></p>
        </div>
    </section>
)

export default Profile