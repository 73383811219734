import React from "react"
import { Link } from "gatsby"

const Header = () => (
    <header className="header">
        <Link to={"/"} className="header__link">
          <svg className="header__kirakira1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M247.355 106.9C222.705 82.241 205.833 39.18 197.46 0c-8.386 39.188-25.24 82.258-49.899 106.917-24.65 24.642-67.724 41.514-106.896 49.904 39.188 8.373 82.254 25.235 106.904 49.895 24.65 24.65 41.522 67.72 49.908 106.9 8.373-39.188 25.24-82.258 49.886-106.917 24.65-24.65 67.724-41.514 106.896-49.904-39.179-8.373-82.245-25.244-106.904-49.895zm160.116 197.439c-14.714-14.721-24.81-40.46-29.812-63.864-5.011 23.404-15.073 49.142-29.803 63.872-14.73 14.714-40.464 24.801-63.864 29.812 23.408 5.01 49.134 15.081 63.864 29.811 14.73 14.722 24.81 40.46 29.82 63.864 5.001-23.413 15.081-49.142 29.802-63.872 14.722-14.722 40.46-24.802 63.856-29.82-23.395-5.002-49.133-15.081-63.863-29.803zm-261.119 50.363c-4.207 19.648-12.655 41.263-25.019 53.626-12.362 12.354-33.968 20.82-53.613 25.027 19.645 4.216 41.251 12.656 53.613 25.027 12.364 12.362 20.829 33.96 25.036 53.618 4.203-19.658 12.655-41.255 25.023-53.626 12.354-12.362 33.964-20.82 53.605-25.035-19.64-4.2-41.251-12.656-53.613-25.019-12.36-12.354-20.829-33.969-25.032-53.618z"/>
          </svg>
          <div className="header__title">
            <h1 className="header__maintitle">HSP、フリーランス翻訳家になる。</h1>
            <p className="header__subtitle">ストレスフリーで自分らしく働こう</p>
          </div>
          <svg className="header__kirakira2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M247.355 106.9C222.705 82.241 205.833 39.18 197.46 0c-8.386 39.188-25.24 82.258-49.899 106.917-24.65 24.642-67.724 41.514-106.896 49.904 39.188 8.373 82.254 25.235 106.904 49.895 24.65 24.65 41.522 67.72 49.908 106.9 8.373-39.188 25.24-82.258 49.886-106.917 24.65-24.65 67.724-41.514 106.896-49.904-39.179-8.373-82.245-25.244-106.904-49.895zm160.116 197.439c-14.714-14.721-24.81-40.46-29.812-63.864-5.011 23.404-15.073 49.142-29.803 63.872-14.73 14.714-40.464 24.801-63.864 29.812 23.408 5.01 49.134 15.081 63.864 29.811 14.73 14.722 24.81 40.46 29.82 63.864 5.001-23.413 15.081-49.142 29.802-63.872 14.722-14.722 40.46-24.802 63.856-29.82-23.395-5.002-49.133-15.081-63.863-29.803zm-261.119 50.363c-4.207 19.648-12.655 41.263-25.019 53.626-12.362 12.354-33.968 20.82-53.613 25.027 19.645 4.216 41.251 12.656 53.613 25.027 12.364 12.362 20.829 33.96 25.036 53.618 4.203-19.658 12.655-41.255 25.023-53.626 12.354-12.362 33.964-20.82 53.605-25.035-19.64-4.2-41.251-12.656-53.613-25.019-12.36-12.354-20.829-33.969-25.032-53.618z"/>
          </svg>
        </Link>
      </header>
  )
  
  export default Header