import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/header"
import Footer from "../components/footer"

import "./destyle.css"
import "./layout.scss"

const Layout = ({ children }) => (
    <div>
        <Helmet>
            <link href="https://fonts.googleapis.com/css2?family=Kaisei+Decol&display=swap" rel="stylesheet" />
        </Helmet>
        <Header />

        {children}

        <Footer />
    </div>
)

export default Layout